.job-dependencies {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column
}

.job-dependencies .dependencies-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  border-bottom: 1px solid lightgray
}

.job-dependencies .dependencies-actions .dependencies-partition-label {
  padding: 0 10px;
  font-size: 13px
}

.job-dependencies .dependencies-actions .dependencies-partition-btn-group .dependencies-partition-btn {
  height: 30px;
  min-width: 200px;
  padding-right: 30px;
  font-size: 12px;
  border: 1px solid lightgray
}

.job-dependencies .dependencies-actions .dependencies-partition-btn-group .dependencies-partition-btn .caret-icon {
  position: absolute;
  top: 8px;
  right: 9px;
  color: inherit
}

.job-dependencies .dependencies-actions .dependencies-partition-btn-group .dependencies-partition-menu {
  width: 100%;
  font-size: 12px
}

.job-dependencies .dependencies-actions .dependencies-partition-btn-group .dependencies-partition-menu li:hover {
  background-color: lightgrey
}

.job-dependencies .dependencies-loading {
  padding: 10px
}

.job-dependencies .dependencies-panel {
  -webkit-flex: 1;
  flex: 1;
  overflow: auto
}

.job-dependencies .dependencies-panel .dependencies-table {
  border-collapse: separate;
  border-spacing: 0
}

.job-dependencies .dependencies-panel .dependencies-table thead tr th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  font-weight: normal;
  background-color: #f0f0f0;
  border-bottom: 1px solid lightgray
}

.job-dependencies .dependencies-panel .dependencies-table thead tr th.first-cell {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
  min-width: 480px;
  max-width: 480px;
  z-index: 2;
  border-right: 1px solid lightgray;
  padding: 0 10px;
  vertical-align: middle;
  font-size: 13px
}

.job-dependencies .dependencies-panel .dependencies-table thead tr th.hours-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  vertical-align: top
}

.job-dependencies .dependencies-panel .dependencies-table thead tr th.hours-header .hour {
  width: 100px;
  height: 36px;
  padding: 2px 5px;
  border-right: 1px solid lightgray;
  white-space: nowrap
}

.job-dependencies .dependencies-panel .dependencies-table tbody tr th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
  min-width: 480px;
  max-width: 480px;
  border-right: 1px solid lightgray;
  background-color: #fbfbfb;
  vertical-align: top;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.job-dependencies .dependencies-panel .dependencies-table tbody tr td {
  border-right: 1px solid lightgray;
  background-color: #fbfbfb
}

.job-dependencies .dependencies-panel .dependencies-table tbody tr.dependency th {
  height: 24px;
  background-color: #f0f0f0;
  padding: 5px 10px
}

.job-dependencies .dependencies-panel .dependencies-table tbody tr.dependency td {
  background-color: #f0f0f0
}

.job-dependencies .dependencies-panel .dependencies-table tbody tr.partition th {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  height: 24px;
  padding: 5px 5px 5px 25px
}

.job-dependencies .dependencies-panel .dependencies-table tbody tr.partition th .btn-icon {
  padding: 5px;
  font-size: 10px
}

.job-dependencies .dependencies-panel .dependencies-table tbody tr.partition td {
  position: relative
}

.job-dependencies .dependencies-panel .dependencies-table tbody tr.partition td .feed-state {
  position: absolute;
  top: 5px;
  height: 12px;
  border-left: 2px solid #0078d4;
  border-right: 2px solid #0078d4;
  background-color: #57c17b
}

.job-dependencies .dependencies-panel .dependencies-table tbody tr.partition td .feed-state:hover {
  box-shadow: 0 0 8px 2px #0078d4
}

.job-dependencies .dependencies-panel .dependencies-table tbody tr.triggers th {
  height: 24px;
  padding: 5px 5px 5px 45px
}

.job-dependencies .dependencies-panel .dependencies-table tbody tr.triggers td {
  position: relative
}

.job-dependencies .dependencies-panel .dependencies-table tbody tr.triggers td .trigger-state {
  position: absolute;
  top: 5px;
  height: 12px;
  background-color: gray
}

.job-dependencies .dependencies-panel .dependencies-table tbody tr.triggers td .trigger-state:hover {
  box-shadow: 0 0 8px 2px #0078d4
}

.job-dependencies .dependencies-panel .dependencies-table tbody tr.triggers td .trigger-state.waiting {
  background-color: #006e8a
}

.job-dependencies .dependencies-panel .dependencies-table tbody tr.triggers td .trigger-state.ready {
  background-color: #00bcf2
}

.job-dependencies .dependencies-panel .dependencies-table tbody tr.triggers td .trigger-state.should-trigger {
  background-color: #9e3533
}

.job-dependencies .dependencies-panel .dependencies-table tbody tr.triggers td .trigger-state.triggered {
  border-left: 2px solid #57c17b;
  border-right: 2px solid #57c17b;
  background-color: #57c17b
}

.job-dependencies .dependencies-flex-spacer {
  -webkit-flex: 1;
  flex: 1
}

.job-dependencies-feed-state-tooltip,
.job-dependencies-trigger-state-tooltip {
  max-width: 300px;
  min-width: 300px
}

.job-dependencies-feed-state-tooltip table th,
.job-dependencies-trigger-state-tooltip table th {
  padding: 4px
}

.job-dependencies-feed-state-tooltip table td,
.job-dependencies-trigger-state-tooltip table td {
  padding: 4px
}